const Footer = () => {
    return (
        <div className="footer">
            <div>
                Copyright © 2024, Christopher Martinez
            </div>
        </div>
    )
}

export default Footer;